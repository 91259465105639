<template>
    <div>
        <b-container>
            <section class="sponsorship">
                <div class="sector">
                    <h2 class="main-title mb-4">Sponsorship offer</h2>
                    <b-row>
                        <b-col cols="12" md="6" lg="3">
                            <div class="sponsorship__plan">
                                <div class="sponsorship__box sponsorship__box--top">
                                    <h3 class="sponsorship__title">Free</h3>
                                </div>
                                <p class="sponsorship__amount">$0<span class="sponsorship__frequency">/year</span></p>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company profile & unlimited number of events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company location on the map
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Customer support
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Priority listing of company, events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Badges
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />'Featured' ribbon on company, events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Company star rating
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Set of business statistics
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Ability to take part in an interview
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Social media campaign
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Priority listing of company above STANDARD
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Premium customer support
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Company mapped on the Leaders Matrix
                                    </p>
                                </div>
                                <div class="sponsorship__box">
                                    <Spinner v-if="!getUserDataPending" class="mt-1" size="small" line-fg-color="#e91e63" />
                                    <div v-else>
                                        <router-link v-if="!getUserName" to="/signup" class="details-button details-button--green details-button--full mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Sign in/Sign up</router-link>
                                        <button v-else disabled class="details-button details-button--green details-button--full mt-0 disabled-element"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Already selected</button>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" lg="3">
                            <div class="sponsorship__plan">
                                <div class="custom-ribbon custom-ribbon--small">
                                    <span class="custom-ribbon__text custom-ribbon__text--light-green">MOST POPULAR</span>
                                </div>
                                <div class="sponsorship__box sponsorship__box--top">
                                    <h3 class="sponsorship__title">Standard</h3>
                                </div>
                                <p class="sponsorship__amount">$200<span class="sponsorship__frequency">/year</span></p>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company profile & unlimited number of events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company location on the map
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Customer support
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Priority listing of company, events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Badges
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />'Featured' ribbon on company, events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company star rating
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Set of business statistics
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Ability to take part in an interview
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Social media campaign
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Priority listing of company above STANDARD
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Premium customer support
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Company mapped on the Leaders Matrix
                                    </p>
                                </div>
                                <div class="sponsorship__box">
                                    <Spinner v-if="!getUserDataPending || !getUserProfilePending" class="mt-1" size="small" line-fg-color="#e91e63" />
                                    <div v-else>
                                        <router-link v-if="!getUserName" to="/signup" class="details-button details-button--green details-button--full mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Sign in/Sign up</router-link>
                                        <button disabled v-else-if="getUserProfile && getUserProfile[0].type == 2 && (getUserProfile[0].subscription == 1 || getUserProfile[0].subscription == 2)" class="details-button details-button--green details-button--full mt-0 disabled-element"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Already selected</button>
                                        <button v-else :disabled="getDisabledLoader" :class="getDisabledLoader ? 'disabled-element' : ''" @click.prevent="subscribeToStandard" class="details-button details-button--green details-button--full mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Select plan</button>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" lg="3">
                            <div class="sponsorship__plan">
                                <div class="sponsorship__box sponsorship__box--top">
                                    <h3 class="sponsorship__title">Premium</h3>
                                </div>
                                <p class="sponsorship__amount">$500<span class="sponsorship__frequency">/year</span></p>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company profile & unlimited number of events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company location on the map
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Customer support
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Priority listing of company, events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Badges
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />'Featured' ribbon on company, events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company star rating
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Set of business statistics
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Ability to take part in an interview
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Social media campaign
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Priority listing of company above STANDARD
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Premium customer support
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature sponsorship__feature--excluded">
                                        <v-icon name="check" scale="1" />Company mapped on the Leaders Matrix
                                    </p>
                                </div>
                                <div class="sponsorship__box">
                                    <Spinner v-if="!getUserDataPending || !getUserProfilePending" class="mt-1" size="small" line-fg-color="#e91e63" />
                                    <div v-else>
                                        <router-link v-if="!getUserName" to="/signup" class="details-button details-button--green details-button--full mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Sign in/Sign up</router-link>
                                        <button disabled v-else-if="getUserProfile && getUserProfile[0].type == 3 && (getUserProfile[0].subscription == 1 || getUserProfile[0].subscription == 2)" class="details-button details-button--green details-button--full mt-0 disabled-element"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Already selected</button>
                                        <button v-else :disabled="getDisabledLoader" :class="getDisabledLoader ? 'disabled-element' : ''" @click.prevent="subscribeToPremium" class="details-button details-button--green details-button--full mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Select plan</button>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6" lg="3">
                            <div class="sponsorship__plan">
                                <div class="sponsorship__box sponsorship__box--top">
                                    <h3 class="sponsorship__title">Ultimate</h3>
                                </div>
                                <p class="sponsorship__amount">$1000<span class="sponsorship__frequency">/year</span></p>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company profile & unlimited number of events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company location on the map
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Customer support
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Priority listing of company, events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Badges
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />'Featured' ribbon on company, events & job offers
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company star rating
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Set of business statistics
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Ability to take part in an interview
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Social media campaign
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Priority listing of company above PREMIUM
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Premium customer support
                                    </p>
                                </div>
                                <div class="sponsorship__element">
                                    <p class="sponsorship__feature">
                                        <v-icon name="check" scale="1" />Company mapped on the Leaders Matrix
                                    </p>
                                </div>
                                <div class="sponsorship__box">
                                    <Spinner v-if="!getUserDataPending || !getUserProfilePending" class="mt-1" size="small" line-fg-color="#e91e63" />
                                    <div v-else>
                                        <router-link v-if="!getUserName" to="/signup" class="details-button details-button--green details-button--full mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Sign in/Sign up</router-link>
                                        <button disabled v-else-if="getUserProfile && getUserProfile[0].type == 4 && (getUserProfile[0].subscription == 1 || getUserProfile[0].subscription == 2)" class="details-button details-button--green details-button--full mt-0 disabled-element"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Already selected</button>
                                        <button v-else :disabled="getDisabledLoader" :class="getDisabledLoader ? 'disabled-element' : ''" @click.prevent="subscribeToUltimate" class="details-button details-button--green details-button--full mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Select plan</button>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <p class="faq__text mt-3 text-right"><simple-line-icons icon="info" size="small" color="#504e70" /> Before making a purchase check your credit card/debit card spending limits.</p>
                </div>
            </section>
        </b-container>
        <div v-if="getBackdropLoader" class="backdrop">
            <Spinner class="backdrop__loader" size="80" :line-size="Number(lineSize)" line-fg-color="#e91e63" />
        </div>
        <div id="verificationGateway"></div>
    </div>
</template>

<script>
import 'vue-awesome/icons/check'
import Icon from 'vue-awesome/components/Icon'
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
export default {
  components: {
    'v-icon': Icon,
    SimpleLineIcons,
    Spinner
  },
  data: function () {
    return {
      lineSize: 5
    }
  },
  methods: {
    subscribeToStandard () {
      this.$store.dispatch('subscribeToStandard')
    },
    subscribeToPremium () {
      this.$store.dispatch('subscribeToPremium')
    },
    subscribeToUltimate () {
      this.$store.dispatch('subscribeToUltimate')
    }
  },
  computed: {
    ...mapGetters([
      'getUserDataPending',
      'getUserProfilePending',
      'getUserName',
      'getUserProfile',
      'getBackdropLoader',
      'getDisabledLoader'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('getUserData')
    this.$store.dispatch('getUserProfile')
  }
}
</script>
